import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Register the ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

// Wait for DOM to be ready
document.addEventListener('DOMContentLoaded', () => {
  // 0. Hero section staggered fade-in with subtle upward movement
  const heroElements = [
    document.getElementById('hero-logo'),
    document.getElementById('hero-landscape'),
    document.getElementById('hero-bottles')
  ];
  
  // Set initial state for hero elements - invisible and translated down
  gsap.set(heroElements, { 
    autoAlpha: 0,
    y: 20
  });
  
  // Create staggered animation timeline for hero elements
  const heroTimeline = gsap.timeline();
  
  heroTimeline.to(heroElements, {
    duration: 0.8,
    autoAlpha: 1,
    y: 0,
    ease: "power2.out",
    stagger: 0.2 // 200ms stagger between each element
  });
  
  // 1. Animation for wine bottles (vertical float animation)
  const bottles = document.querySelectorAll('.wine-bottle img:not(:first-child)');
  const shadows = document.querySelectorAll('.wine-bottle img:first-child');
  
  // Create a timeline for each bottle that will loop indefinitely
  bottles.forEach((bottle, index) => {
    // Create timeline for gentle floating effect
    gsap.to(bottle, {
      y: "-0.625rem", // Move up slightly (10px)
      duration: 2,
      ease: "quad.inOut",
      repeat: -1, // Repeat infinitely
      yoyo: true, // Go back and forth
      delay: index * 0.5 // Stagger the animations
    });
    
    // Keep the shadow in place (not moving)
    gsap.set(shadows[index], { y: 0 });
  });

  // 2. Fade in elements during scroll
  // Select all elements that should fade in
  const fadeElements = document.querySelectorAll('.fade-in');
  
  // Set initial state - invisible
  gsap.set(fadeElements, { autoAlpha: 0 });
  
  // Create animation for each element
  fadeElements.forEach(element => {
    gsap.to(element, {
      scrollTrigger: {
        trigger: element,
        start: "top 80%", // Start animation when element is 80% from the top of the viewport
        toggleActions: "play none none none"
      },
      duration: 1,
      autoAlpha: 1,
      ease: "power2.out"
    });
  });

  // 3. Parallax effect between text and images in the wine sections with consistent speed
  const productSections = document.querySelectorAll('.product-section');
  
  // Check if screen width is at least 768px
  const isDesktop = () => window.innerWidth >= 768;
  
  productSections.forEach(section => {
    const media = section.querySelector('.product-media');
    const textContainer = section.querySelector('h2').closest('div.flex');
    
    if (media && textContainer) {
      // Calculate parallax amount in rem units (based on viewport height)
      const viewportHeight = window.innerHeight / 16; // Convert to rem
      const parallaxAmount = viewportHeight * 0.15; // 15% of viewport height in rem
      
      // Parallax for media: recenter around 0 at 50% scroll progress
      const mediaAnimation = gsap.to(media, {
        scrollTrigger: {
          trigger: section,
          start: "top bottom",
          end: "bottom top",
          scrub: 0.5,
          onUpdate: (self) => {
            // Only apply parallax if on desktop
            if (isDesktop()) {
              const progress = self.progress;
              // When progress is 0.5, (progress - 0.5) equals 0 so translateY is 0 (centered)
              media.style.transform = `translateY(${(progress - 0.5) * parallaxAmount}rem)`;
            } else {
              // Reset transform on mobile
              media.style.transform = '';
            }
          }
        },
        ease: "none"
      });
      
      // Parallax for text: use the inverse so both elements align at center (translateY 0)
      const textAnimation = gsap.to(textContainer, {
        scrollTrigger: {
          trigger: section,
          start: "top bottom",
          end: "bottom top",
          scrub: 0.5,
          onUpdate: (self) => {
            // Only apply parallax if on desktop
            if (isDesktop()) {
              const progress = self.progress;
              textContainer.style.transform = `translateY(${-(progress - 0.5) * parallaxAmount}rem)`;
            } else {
              // Reset transform on mobile
              textContainer.style.transform = '';
            }
          }
        },
        ease: "none"
      });
      
      // Update parallax on window resize
      window.addEventListener('resize', () => {
        // Force ScrollTrigger to recalculate
        ScrollTrigger.refresh();
      });
    }
  });
});